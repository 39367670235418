import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query PostQuery
      {
        allWordpressPost {
            edges {
              node {
                id
                slug
                title
                content
                excerpt
                date
                modified
                template
              }
            }
          }
      }
    `}
    render={data => (
      <div>
          <p>{JSON.stringify(data)}</p>
       
      </div>
    )}
  />
)